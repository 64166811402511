<template>
  <div>
    <div class="list flex_wrap">
      <div class="item flex_space_between transition" v-for="item in list" :key="item.id" @click="itemClick(item)">
        <div class="left flex_direction_column">
          <div class="title one-line">{{ item.name }}</div>
          <div class="province">{{ item.province }}·{{ item.city }}</div>
        </div>
        <div class="right flex_direction_column">
          <div class="time">{{ item.start_time }}</div>
          <div class="find flex">
            <img src="@/assets/image/img/20.png" />
            <span>成绩查询</span>
          </div>
        </div>
      </div>
    </div>
    <Lack
      v-if="list.length == 0"
      imgWidth="115px"
      imgHeight="76px"
      text="暂无数据"
      :imgSrc="require('@/assets/image/img/12.png')"
    ></Lack>
    <div class="pagination">
      <el-pagination
        @current-change="currentChange"
        background
        layout="prev, pager, next"
        :page-size="15"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import Lack from '@/components/lack/lack.vue'
import { matchAchievement } from '@/service/api/index'
export default {
  props: {
    year: {
      type: String,
      default() {
        return ''
      }
    },
    name: {
      type: String,
      default() {
        return ''
      }
    }
  },
  components: { Lack },
  data() {
    return {
      total: 0,
      list: [],
      params: {
        page: 1,
        name: '',
        year: ''
      }
    }
  },

  mounted() {
    this.matchAchievement()
  },

  methods: {
    // 成绩赛事列表
    async matchAchievement() {
      const res = await matchAchievement(this.params)
      this.list = res.msg.data
      this.total = res.msg.total
      console.log(res)
    },
    itemClick(item) {
      this.$router.push({
        path: '/score-query',
        query: {
          id: item.id,
          active_id: item.active_id
        }
      })
    },
    currentChange(page) {
      this.params.page = page
      this.matchAchievement()
      console.log(page)
    }
  },
  watch: {
    year: {
      handler(val) {
        this.params.year = val
        this.matchAchievement()
      },
      deep: true
    },
    name: {
      handler(val) {
        console.log('key', val)
        this.params.name = val
        this.matchAchievement()
      },
      deep: true
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-pager li {
  font-weight: normal;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: @color_one;
  color: #fff;
}
.pagination {
  display: flex;
  margin-top: 29px;
  margin-bottom: 35px;
  justify-content: center;
}
.list {
  width: 100%;

  .item {
    width: 100%;
    background-color: #fff;
    padding: 24px 23px;
    margin-top: 20px;
    cursor: pointer;
    border-radius: 5px;
    .left {
      width: 1200px;
      .title {
        height: 30px;
        font-size: 22px;
        line-height: 30px;
      }
      .province {
        height: 35px;
        font-size: 16px;
        margin-top: 15px;
        line-height: 35px;
        color: #777777;
      }
    }
    .right {
      .time {
        height: 35px;
        font-size: 16px;
        line-height: 35px;
        color: #777777;
      }
      .find {
        margin-top: 17px;
        img {
          width: 22px;
          height: 22px;
          margin-right: 6px;
        }
        span {
          height: 25px;
          font-size: 18px;
          line-height: 25px;
        }
      }
    }
  }
}
</style>
