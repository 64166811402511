<template>
  <div>
    <div class="topImg">
      <img :src="$store.state.config.ach_banner" />
    </div>
    <div class="nav">
      <div class="content flex_direction_column">
        <div class="search flex_space_between">
          <input v-model="key" placeholder="输入赛事名称查询赛事成绩" />
          <img src="@/assets/image/img/18.png" />
        </div>
        <div class="date flex">
          <div class="label"></div>
          <div class="list flex_wrap">
            <div
              class="item"
              :class="{ active: index == currentIndex }"
              v-for="(item, index) in year"
              :key="index"
              @click="itemClick(index)"
            >
              {{ item.label }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content" v-if="year">
      <ScoreList :name="key" :year="year[currentIndex].label"></ScoreList>
    </div>
    <Loading v-if="!year"></Loading>
  </div>
</template>

<script>
import { getYear } from '@/service/api/index'
import ScoreList from '@/components/list/score.vue'
export default {
  components: { ScoreList },
  data() {
    return {
      currentIndex: 0,
      year: '',
      key: ''
    }
  },

  mounted() {
    this.getYear()
  },

  methods: {
    // 获取日期
    async getYear() {
      const res = await getYear()
      this.year = res.msg
    },
    itemClick(index) {
      this.currentIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
.topImg {
  width: 100%;
  height: 300px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.nav {
  width: 100%;
  // height: 220px;
  background-color: #fff;
  .content {
    .search {
      width: 1200px;
      padding-bottom: 15px;
      margin: 42px auto 0 auto;
      border-bottom: 1px solid #eee;
      input {
        width: 354px;
        height: 28px;
        line-height: 28px;
        font-size: @font_size_20;
      }
      img {
        width: 25px;
        height: 25px;
        cursor: pointer;
      }
    }
    .date {
      padding: 24px 35px;
      .label {
        height: 30px;
        line-height: 30px;
        margin-right: 45px;
        margin-bottom: 10px;
        font-size: @font_size_16;
      }
      .active {
        color: #fff;
        background-color: @color_two;
      }
      .list {
        flex: 1;
        .item {
          width: 70px;
          height: 30px;

          cursor: pointer;
          line-height: 30px;
          margin-right: 17px;
          border-radius: 3px;
          text-align: center;
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>
